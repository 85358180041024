<template>
  <div class="process" ref="process">
    <div class="flex" style="position: relative;" v-for="(item, index) in stepData" :key="index">
      <section :class="['content', item.status === 'completed' ? 'completed' : item.status === 'progress' ? 'progress' : 'undone']">
        <div class="process-garden">
          <span class="text" v-if="item.status !== 'completed'">{{ index + 1 }}</span>
          <i v-else class="el-icon-check"></i>
        </div>
        <span class="record" v-if="isShowCard() && isPermission(detailInfo.orderInfo, $route.query.customer) && item.status === 'progress' && auditListData.length" @click="showAudit">审核记录</span>
        <span :class="['name', item.name.length > 10 ? 'my-position' : '']">{{ item.name }}</span>
        <!-- 当前第几期 -->
        <!-- <span
          :style="item.name.length > 10 ? 'margin-top:40px' : 'margin-top: 10px;'"
          v-if="item.status === 'progress' && detailInfo.orderInfo.orderStatus != 'DQS' && detailInfo.orderInfo.orderStatus != 'DFWQ' && detailInfo.orderInfo.orderStatus != 'ZFC'"
          >{{ detailInfo.orderInfo.currentTermStr }}</span
        > -->
      </section>
      <div v-if="getLineIsShow(index).falg" :style="{width: getLineIsShow(index).width}" class="process-line"></div>
    </div>
    <!-- 审核记录表格 -->
    <dialog-audit-list :detailInfo="detailInfo" :auditListData="auditListData" ref="dialogAuditList" />
  </div>
</template>
<script>
import {commonMethods} from '@/modules/mixin/order-mixin.js';
import {permission} from '@/modules/mixin/permission-mixin.js';
import dialogAuditList from './operate/dialog-audit-list.vue';
import {getAuditProcessList} from '@/modules/User/api.js';
export default {
  props: {
    detailInfo: {
      type: Object
    }
  },
  data() {
    return {
      stepData: [],
      auditListData: {}
    };
  },
  mixins: [commonMethods, permission],
  mounted() {
    // 获取当前流程步骤
    this.getStepData();
    // 获取审核列表数据
    this.getAuditProcessList();
  },
  components: {
    dialogAuditList
  },
  watch: {
    detailInfo: {
      deep: true,
      handler: function() {
        // 获取操作步骤数据
        this.getStepData();
        // 获取审核列表数据
        this.getAuditProcessList();
      }
    }
  },
  methods: {
    // 根据当前是卖家还是买家身份判断是否显示对应的模块
    isShowCard() {
      let flag = false;
      if (this.$route.query.customer == 'sellder') {
        ['DFH', 'DKP'].includes(this.detailInfo.orderInfo.orderStatus) ? (flag = true) : '';
      } else {
        ['DSH', 'DZF'].includes(this.detailInfo.orderInfo.orderStatus) ? (flag = true) : '';
      }
      return flag;
    },
    // 获取审核列表的数据
    async getAuditProcessList() {
      let params = {
        orderId: this.$route.query.orderid,
        operationName: this.getOprtStatus,
        currentTerm: this.detailInfo.orderInfo.currentTerm
      };
      let res = await getAuditProcessList(params);
      if (res.errno === 0) this.auditListData = res.data;
    },
    showAudit() {
      this.$refs.dialogAuditList.dialogVisible = true;
    },
    // 获取流程步骤
    getStepData() {
      if (this.detailInfo.orderInfo.orderProcess === 'HK') {
        // 已完成：completed,进行中：progress,未完成：undone
        this.stepData = [
          {
            name: '合同签署',
            status: 'undone'
          },
          {
            name: '供应商发货',
            status: 'undone'
          },
          {
            name: '买家签收',
            status: 'undone'
          },
          {
            name: '供应商开发票',
            status: 'undone'
          },
          {
            name: '买家支付',
            status: 'undone'
          },
          {
            name: '供应商收款',
            status: 'undone'
          }
        ];
      }
      if (this.detailInfo.orderInfo.orderProcess === 'KH') {
        // 已完成：completed,进行中：progress,未完成：undone
        this.stepData = [
          {
            name: '合同签署',
            status: 'undone'
          },
          {
            name: '买家支付',
            status: 'undone'
          },
          {
            name: '供应商确认收款、发货、开发票',
            status: 'undone'
          },
          {
            name: '买家签收',
            status: 'undone'
          }
        ];
      }
      this.initializationState();
    },
    // 动态计算每个圆圈之间线的宽度
    getLineIsShow(index) {
      let num = this.detailInfo.orderInfo.orderProcess === 'HK' ? 5 : 3;
      let myObj = {
        falg: true,
        // 减去200是因为有100的padding值
        width: `${(this.$refs.process.offsetWidth - 200) / num - 100}px`
      };
      if (this.detailInfo.orderInfo.orderProcess === 'HK' && index === 5) myObj.falg = false;
      if (this.detailInfo.orderInfo.orderProcess === 'KH' && index === 3) myObj.falg = false;
      return myObj;
    },
    // 根据不同状态获取对应的中文
    initializationState() {
      if (this.detailInfo.orderInfo.orderProcess === 'HK') {
        ['DFWQ', 'DQS'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(0) : '';
        ['DFH'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(1) : '';
        ['DSH'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(2) : '';
        ['DKP'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(3) : '';
        ['DZF'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(4) : '';
        ['ZFC'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(5) : '';
        ['END'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(99) : '';
      }
      if (this.detailInfo.orderInfo.orderProcess === 'KH') {
        ['DFWQ', 'DQS'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(0) : '';
        ['DZF'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(1) : '';
        ['DFH', 'DKP'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(2) : '';
        ['DSH'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(3) : '';
        ['END'].includes(this.detailInfo.orderInfo.orderStatus) ? this.restructureData(99) : '';
      }
    },
    restructureData(step) {
      this.stepData.forEach((item, index) => {
        item.status = 'completed';
        index == step ? (item.status = 'progress') : '';
        index > step ? (item.status = 'undone') : '';
      });
    }
  }
};
</script>
<style lang="less" scoped>
.completed {
  color: #3e4768;
  .text {
    color: #3e4768;
  }
  .process-garden {
    border: 2px solid #3e4768;
  }
}
.content {
  width: 100px;
}
.my-position {
  position: absolute;
  top: 40px;
  width: 100%;
  text-align: center;
}
.record {
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: -25px;
  left: 22px;
  border-bottom: 2px solid #409eff;
}
.progress {
  color: #409eff;
  .text {
    color: #409eff;
  }
  .process-garden {
    border: 2px solid #409eff;
  }
}
.undone {
  color: #a7b1c1;
  .text {
    color: #a7b1c1;
  }
  .process-garden {
    border: 2px solid #a7b1c1;
  }
}
.process {
  display: flex;
  padding: 45px 50px;
  background-color: #fff;

  section {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .name,
  .period {
    margin-top: 10px;
  }
  &-garden {
    color: #a7b1c1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border: 2px solid #a7b1c1;
    border-radius: 50%;
    i {
      font-size: 28px;
      color: #3e4768;
    }
  }
  &-line {
    margin-top: 15px;
    width: 100%;
    height: 2px;
    background-color: #a7b1c1;
  }
}
</style>
