var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auditList" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "show-close": false,
            title: "提示",
            visible: _vm.dialogVisible,
            width: "1580px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", { staticClass: "auditList-title" }, [
                    _c("span", [_vm._v("审核记录")]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "auditList-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.auditListData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "createTime",
                  label: "填写时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm
                                .$moment(scope.row.createTime)
                                .format("YYYY-MM-DD hh:mm:ss")
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "createName", label: "填写人" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "userName", label: "审核人" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "userName", label: "审核时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm
                                .$moment(scope.row.approveTime)
                                .format("YYYY-MM-DD hh:MM:ss")
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "approveStatus",
                  label: "审核状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getApproveStatus")(
                                scope.row.approveStatus
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "approveRemarks",
                  label: "原因",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }