<template>
  <div class="auditList">
    <el-dialog :show-close="false" title="提示" :visible.sync="dialogVisible" width="1580px">
      <template #title>
        <div class="auditList-title">
          <span>审核记录</span>
        </div>
      </template>
      <el-table :data="auditListData" border style="width: 100%">
        <el-table-column align="center" prop="createTime" label="填写时间">
          <template slot-scope="scope">
            {{ $moment(scope.row.createTime).format('YYYY-MM-DD hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createName" label="填写人"> </el-table-column>
        <el-table-column align="center" prop="userName" label="审核人"> </el-table-column>
        <el-table-column align="center" prop="userName" label="审核时间">
          <template slot-scope="scope">
            {{ $moment(scope.row.approveTime).format('YYYY-MM-DD hh:MM:ss') }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="approveStatus" label="审核状态">
          <template slot-scope="scope">
            {{ scope.row.approveStatus | getApproveStatus }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="approveRemarks" label="原因"> </el-table-column>
      </el-table>
      <template #footer>
        <div class="auditList-footer">
          <el-button @click="dialogVisible = false" size="mini" type="primary">返回</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    detailInfo: {
      type: Object
    },
    auditListData: {}
  },
  data() {
    return {
      dialogVisible: false,
      tableData: []
    };
  },
  filters: {
    getApproveStatus(val) {
      switch (val) {
        case '0':
          return '无需审核';
        case '1':
          return '待审核';
        case '2':
          return '审核通过';
        case '3':
          return '审核不通过';
      }
    }
  }
};
</script>
<style lang="less" scoped>
.auditList {
  &-title {
    span {
      font-size: 18px;
      &::before {
        content: '';
        margin-right: 5px;
        display: inline-block;
        height: 13px;
        width: 2px;
        background-color: #01a3fd;
      }
    }
  }
  &-footer {
    text-align: left;
  }
}
</style>
