var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "process", staticClass: "process" },
    [
      _vm._l(_vm.stepData, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "flex",
            staticStyle: { position: "relative" },
          },
          [
            _c(
              "section",
              {
                class: [
                  "content",
                  item.status === "completed"
                    ? "completed"
                    : item.status === "progress"
                    ? "progress"
                    : "undone",
                ],
              },
              [
                _c("div", { staticClass: "process-garden" }, [
                  item.status !== "completed"
                    ? _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(index + 1)),
                      ])
                    : _c("i", { staticClass: "el-icon-check" }),
                ]),
                _vm.isShowCard() &&
                _vm.isPermission(
                  _vm.detailInfo.orderInfo,
                  _vm.$route.query.customer
                ) &&
                item.status === "progress" &&
                _vm.auditListData.length
                  ? _c(
                      "span",
                      { staticClass: "record", on: { click: _vm.showAudit } },
                      [_vm._v("审核记录")]
                    )
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: ["name", item.name.length > 10 ? "my-position" : ""],
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
              ]
            ),
            _vm.getLineIsShow(index).falg
              ? _c("div", {
                  staticClass: "process-line",
                  style: { width: _vm.getLineIsShow(index).width },
                })
              : _vm._e(),
          ]
        )
      }),
      _c("dialog-audit-list", {
        ref: "dialogAuditList",
        attrs: { detailInfo: _vm.detailInfo, auditListData: _vm.auditListData },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }